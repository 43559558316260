import { IGatsbyImageData } from "../../node_modules/gatsby-plugin-image"

export interface Product {
  id: string
  name: string
  // The slug is how we match the stripe product with the json product.
  // It is also used to find the correct bundle to attach to the fulfilment email.
  // So make sure it matches the name of the zip file,
  // eg: "supabase-authentication-bundle-kit" -> "supabase-authentication-bundle-kit.zip"
  slug: string
  currency: string
  unitAmount: number
  featuredImage: IGatsbyImageData
  screenshotImages: IGatsbyImageData[]
  description: string[]
  features: string[]
}

export const normalizedProducts = (
  jsonProducts: any[],
  stripeProducts: any[]
): Product[] => {
  return jsonProducts.map(jsonProduct => {
    const matchingStripeProduct = stripeProducts.find(
      stripeProduct => stripeProduct.product.metadata.slug == jsonProduct.slug
    )
    return normalizedProduct(jsonProduct, matchingStripeProduct)
  })
}

export const normalizedProduct = (
  jsonProduct: any,
  stripeProduct: any
): Product => {
  const {
    name,
    slug,
    featuredImage,
    screenshotImages,
    description,
    features,
  } = jsonProduct
  const { currency, unit_amount, id } = stripeProduct
  const featuredImageData: IGatsbyImageData =
    featuredImage.childImageSharp.gatsbyImageData
  const screenshotImagesData: IGatsbyImageData[] = screenshotImages.map(
    image => image.childImageSharp.gatsbyImageData
  )

  return {
    id: id,
    name: name,
    currency: currency,
    unitAmount: unit_amount,
    slug: slug,
    featuredImage: featuredImageData,
    screenshotImages: screenshotImagesData,
    description: description,
    features: features,
  }
}
