/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe, Stripe } from "@stripe/stripe-js"

let stripePromise: Promise<Stripe | null>

export const getStripe = () => {
  const publishableKey = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY

  if (!stripePromise && publishableKey) {
    try {
      stripePromise = loadStripe(publishableKey)
    } catch (error) {
      console.log(`Stripe Promise error: ${error}`)
      return null
    }
  }
  return stripePromise
}
