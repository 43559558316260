export const formatPrice = (amount: number, currency: string) => {
  const price = (amount / 100).toFixed(2)
  let numberFormat = Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })

  return +price ? numberFormat.format(+price) : ""
}
